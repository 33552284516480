import React from 'react';
import SEO from '../../components/SEO';
import { graphql, withPrefix, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import JotformEmbed from 'react-jotform-embed';

import Layout from '../../layouts/index';
import Call from '../../components/Call';

const Referral = (props) => {
  const referral = props.data.referral;
  return (
    <Layout bodyClass="page-contact contact-page">
      <SEO
        title="Minnesota HVAC Referral Program"
        description="Recommend your friends and Family for Minnesota HVAC Installtion"
      />
      <div className="relative bg-red-800 max-w-full">
        <div className="h-56 bg-red-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <GatsbyImage
            alt="best AC Furnace Installation, Service, And Repair"
            className="w-full h-full object-cover"
            image={referral.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 max-w-full">
          <div className="md:ml-auto md:w-1/2 md:pl-10 max-w-full">
            <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300 max-w-full">
              Refer a Friend Program
            </h2>
            <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl max-w-full">
              Do you know of anyone who needs their HVAC system replaced?
            </p>
            <p className="mt-3 text-lg text-gray-300">
              Next time you are with meeting with your friend let them know
              about our Refer a Friend program and then fill out the short form
              below.
            </p>
            <p className="mt-3 text-lg text-gray-300">
              We will contact your referral to schedule a free estimate and when
              they hire LaSalle Heating and Air Conditioning for the sale and
              installation their furnace and/or air conditioner then you will
              get a $100 Visa gift card as a thank you from us!
            </p>
            <p className="mt-3 text-lg text-gray-300">
              There is no limit to the number of referrals you give! That means
              endless opportunities for you to earn $100.
            </p>
          </div>
        </div>
      </div>
      <div className="container22">
        <div>
          <JotformEmbed src="https://form.jotform.com/210737040341140" />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            image
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
          url
        }
      }
    }
    heroBackground: file(
      relativePath: { eq: "furnace-service-repair-comany.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }

    referral: file(relativePath: { eq: "hvac-service-2021.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    bannerAd: file(
      relativePath: { eq: "minnesota-hvac-furnace-repair-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;
export default Referral;
